import { UUID } from "angular2-uuid";
import { SignInResult } from "../security/session/session.model";

//--------------------------------------------------------------------------------------------
// ENUMS
//--------------------------------------------------------------------------------------------
export enum SignUpResult
{
    SignUpSuccess               = 0,
    EmailAlreadyExistsError     = 1,
}

export enum ZuniWebUserActivationResult
{
    AccountActivatedSuccess     = 0,
    AccountAlreadyActivated     = 1,
    TokenExpiredWarning         = 2,
}

//--------------------------------------------------------------------------------------------
// MODELS
//--------------------------------------------------------------------------------------------
export class UserModel {
    JWT: string;
    USerID: UUID;
    UserFriendlyName: string;
    SignInResult: SignInResult;
}

export class UserSignUpArgs {
    constructor (
        email?: string,
        password?: string,
        name?: string,
        phoneNumber?: string
    ) {
        this.Email = email;
        this.Password = password;
        this.Name = name;
        this.PhoneNumber = phoneNumber;
    }

    Email: string;
    Password: string;
    Name: string;
    PhoneNumber: string;
}