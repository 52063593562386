import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from "@angular/material/icon";

import { SliderComponent } from './slider/slider.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { BrandCarouselComponent } from './brand-carousel/brand-carousel.component';
import { VideoContainerComponent } from './video-container/video-container.component';

@NgModule(
    {
        imports:
            [
                CommonModule,

                FormsModule,
                ReactiveFormsModule,
                MatInputModule,
                MatSelectModule,
                MatIconModule,

                TranslateModule,
            ],
        declarations:
            [
                SliderComponent,
                ContactUsComponent,
                BrandCarouselComponent,
                VideoContainerComponent,
            ],
        exports:
            [
                SliderComponent,
                ContactUsComponent,
                BrandCarouselComponent,
                VideoContainerComponent,
            ]
    }
)
export class ElementsModule
{
}