import { ElementRef, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { SharedServicesProvider } from '../../core/customs/shared/shared-services-provider';
import { NotificationLevel } from '../../core/customs/shared/message-notifier';
import { toUpperCaseWithUnderscores } from '../../core/utility/strings-helper';
import { LocalDataRecipient } from '../../core/customs/shared/local-processing';

import Swal from 'sweetalert2';
import { NavigationExtras } from '@angular/router';
import { queryParams } from '@syncfusion/ej2-base';
import { ZuniWebUserActivationResult } from '../../core/services/users/user.model';

// export enum AsyncCallFlags
// {
//     None                = 0x0000,
//     SetProcessingFlags  = 0x0001,
//     TestAuthentication  = 0x0002,
// }

export abstract class ViewModelBase
{
    //--------------------------------------------------------------------------------------------
    // CONSTRUCTOR
    //--------------------------------------------------------------------------------------------
    constructor(
        protected sharedServicesProvider: SharedServicesProvider,
    )
    {
        // this.m_isProcessingCount    = 0;
        // this.LocalRecipient  = new LocalDataRecipient();
    }

    //--------------------------------------------------------------------------------------------
    // PROPERTIES
    //--------------------------------------------------------------------------------------------
    // public LocalRecipient: LocalDataRecipient;
    public MenuContextualItem: any;
    // private m_pendingLocalAsyncCallsCounts: any = {};
    // private m_isProcessingCount: number;

    // public get IsProcessing(): boolean
    // {
    //     return this.m_isProcessingCount > 0;
    // }

    // public set IsProcessing(value: boolean)
    // {
    //     if ( value )
    //         this.m_isProcessingCount++;
    //     else
    //         this.m_isProcessingCount--;

    //     if (this.m_isProcessingCount == 1 )
    //     {
    //         //this.SharedServicesProvider.overlayManager.open("window-loading", null);
    //     }
    //     else
    //     if (this.m_isProcessingCount <= 0 )
    //     {
    //         //this.SharedServicesProvider.overlayManager.close("window-loading");
    //     }
    // }

    protected get Url(): string
    {
        return this.sharedServicesProvider.router.url;
    }

    protected AllowAnonymous: boolean | undefined;

    private m_langChangingSubscription: Subscription | undefined;

    public NotifyChanges: EventEmitter<void> = new EventEmitter<void>();

    //--------------------------------------------------------------------------------------------
    // COMMAND HANDLERS
    //--------------------------------------------------------------------------------------------
    public OnInit()
    {
        this.AfterViewModelInit();
    }

    public AfterViewInit()
    {
    }

    public AfterContentInit()
    {
    }

    public AfterContentChecked()
    {
    }

    public OnDestroy()
    {
        if (this.m_langChangingSubscription)
        {
            this.m_langChangingSubscription.unsubscribe();
        }
    }

    public OnNavigatingTo(commands: any[], openInNewWindow: boolean = false)
    {
        this.NavigateTo(commands, openInNewWindow);
    }

    //--------------------------------------------------------------------------------------------
    // PUBLIC METHODS
    //--------------------------------------------------------------------------------------------
    public GetCurrentLanguage(): string
    {
        return this.sharedServicesProvider._translateService.currentLang;
    }

    public Translate(key: string): string
    {
        return this.sharedServicesProvider._translateService.instant(key);
    }

    //--------------------------------------------------------------------------------------------
    // PROTECTED METHODS
    //--------------------------------------------------------------------------------------------
    protected AfterViewModelInit()
    {
        this.m_langChangingSubscription = this.sharedServicesProvider._translateService.onLangChange.subscribe(() => this.OnChangingLanguage());
    }

    protected OnChangingLanguage()
    {
    }

    protected SetLanguage(lang: string)
    {
        this.sharedServicesProvider._translateService.use(lang);
        localStorage.setItem('NG_TRANSLATE_LANG_KEY', lang);
    }

    protected NavigateTo(commands: any[], openInNewWindow: boolean = false, extras: NavigationExtras = null)
    {
        if (openInNewWindow)
        {
            let path = '';
            for (let command of commands)
            {
                // convert json params to matrix URi params
                let jsonStr = JSON.stringify(command);
                let matrixStr = jsonStr
                    .replace(/{"/, ';')
                    .replace(/"}/, '/')
                    .replace(/":/g, '=')
                    .replace(/,"/g, ';')
                    .replace(/"/g, '');
                path += matrixStr;
            }
            window.open(path);
        }
        else
        {
            extras == null ? this.sharedServicesProvider.router.navigate(commands) :
                             this.sharedServicesProvider.router.navigate(commands, extras);
        }
    }

    protected NotifyStatus(status: any, typeEnum: any, errorAutoHide: boolean = true, ...params: string[])
    {
        let strStatus = toUpperCaseWithUnderscores(String(typeEnum[status]));
        let level = NotificationLevel.Info;
        let autoHide = true;
        if (strStatus.endsWith("SUCCESS"))
        {
            level = NotificationLevel.Success;
        }
        else if (strStatus.endsWith("WARNING"))
        {
            level = NotificationLevel.Warning;
        }
        else if (strStatus.endsWith("ERROR"))
        {
            level = NotificationLevel.Error;
            autoHide = errorAutoHide;
        }
        let message = strStatus + '_MESSAGE';
        
        this.sharedServicesProvider.messageNotifier.displayMessage(message, level, autoHide, ...params);
    }

    protected SwalNotifyMessage(idx: any, typeEnum: any)
    {
        if(idx == -1) {
            this.NotifyErrorMessage('MESSAGE_CONNECTION_REFUSED', false);
            return;
        }

        let strStatus = toUpperCaseWithUnderscores(String(typeEnum[idx]));

        if (strStatus.endsWith("SUCCESS"))
        {
            this.NotifySuccessMessage('MESSAGE_' + strStatus, false);
        }
        else if (strStatus.endsWith("WARNING"))
        {
            this.NotifyWarningMessage('MESSAGE_' + strStatus, false);
        }
        else if (strStatus.endsWith("ERROR"))
        {
            this.NotifyErrorMessage('MESSAGE_' + strStatus, false)
        }
        else
        {
            this.NotifyInformationMessage('MESSAGE_' + strStatus, false);
        }
    }

    protected NotifyInformationMessage(message: string, showConfirmButton: boolean = true)
    {
        Swal.fire({
            icon: 'info',
            title: this.Translate(message),
            timer: 3000,
            showConfirmButton: showConfirmButton
        });
    }

    protected NotifySuccessMessage(message: string, showConfirmButton: boolean = true)
    {
        Swal.fire({
            icon: 'success',
            title: this.Translate(message),
            timer: 3000,
            showConfirmButton: showConfirmButton
        });
    }

    protected NotifyWarningMessage(message: string, showConfirmButton: boolean = true)
    {
        Swal.fire({
            icon: 'warning',
            title: this.Translate(message),
            timer: 3000,
            showConfirmButton: showConfirmButton
        });
    }

    protected NotifyErrorMessage(message: string, showConfirmButton: boolean = true)
    {
        Swal.fire({
            icon: 'error',
            title: this.Translate(message),
            timer: 3000,
            showConfirmButton: showConfirmButton
        });
    }

    protected async HandleAsyncCall(callback: () => Promise<any>, element: HTMLElement = null)
    {
        // * Ocultar elemento...
        if(element != null)
            element.style.display = 'none';

        // * Crear Spinner en Js como HTMLElement...
        // * Mostrar Spinner...

        try
        {
            await callback();
        }
        catch (err: any)
        {
            // ? Necesario...
            this.NotifyErrorMessage(err);
        }
        finally
        {
            // * Ocultar Spinner...

            // * Mostrar boton...
            if(element != null)
                element.style.display = 'inline'; // ? Validar si 'inline' funciona para todos...
        }
    }

    protected async HandleAsyncCallActiveUser(callback: () => Promise<any>)
    {
        try
        {
            await callback();
        }
        catch (err: any)
        {
            // TODO: Detectar el error de CONNECTION_REFUSED...

            const navigationExtras: NavigationExtras = {
                state: { result: 
                    err.status == 0 ? -1 : parseInt(ZuniWebUserActivationResult.TokenExpiredWarning.toString())
                }
            };

            this.NavigateTo(['/session'], false, navigationExtras);
        }
    }

    // //--------------------------------------------------------------------------------------------
    // // PRIVATE METHODS
    // //--------------------------------------------------------------------------------------------
    // private HandleAsyncCallError(err : Error | HttpErrorResponse, flags : AsyncCallFlags)
    // {
    //     if ( err instanceof HttpErrorResponse )
    //     {
    //         if (err.status === 403)
    //         {
    //             this.NavigateTo(['/forbidden']);
    //         }
    //         else
    //         if ( err.status === 401 )
    //         {
    //             if (!(flags & AsyncCallFlags.TestAuthentication))
    //             {
    //                 this.NavigateTo(['/session/sign-in']);
    //             }
    //         }
    //         else
    //         {
    //             this.NotifyCommunicationErrorMessage(err);
    //         }
    //     }
    //     else
    //         this.NotifyCommunicationErrorMessage(err);
    // }

    // private NotifyCommunicationErrorMessage(error: Error | HttpErrorResponse | any)
    // {
    //     let message : string = "";

    //     if  ( error instanceof Error )
    //     {
    //         message = error.name + ' (' + error.message + ')';
    //     }
    //     else
    //     if ( error instanceof HttpErrorResponse )
    //     {
    //         message =  error.message + ' (' + error.error + ')';
    //     }
    //     else
    //     {
    //         message = 'Error ' + error.status.toString();
    //         if (error.statusText)
    //         {
    //             message += ' (' + error.statusText + ')';
    //         }
    //         if (error._body)
    //         {
    //             message += ': ' + error._body;
    //         }
    //         else if (error.data && error.data.description)
    //         {
    //             // OPENPAY ERRORS
    //             message += ': ' + error.data.description + '.';
    //         }
    //     }

    //     this.NotifyErrorMessage(message, false);
    // }
}
