import { OnInit, AfterViewInit, AfterContentInit, AfterContentChecked, OnDestroy, Component } from '@angular/core';

import { ViewModelBase } from './view-model-base';

@Component({template: ''})
export abstract class UIComponentBase implements OnInit, AfterViewInit, AfterContentInit, AfterContentChecked, OnDestroy
{
    //--------------------------------------------------------------------------------------------
    // CONSTRUCTOR
    //--------------------------------------------------------------------------------------------
    constructor(protected viewModel: ViewModelBase)
    {
    }

    //--------------------------------------------------------------------------------------------
    // PROPERTIES
    //--------------------------------------------------------------------------------------------
    public get vm(): any // for HTML access
    {
        return this.viewModel;
    }

    //--------------------------------------------------------------------------------------------
    // INTERFACE
    //--------------------------------------------------------------------------------------------
    ngOnInit() 
    {
        this.viewModel.OnInit();
    }

    ngAfterViewInit()
    {
        this.viewModel.AfterViewInit();
    }

    ngAfterContentInit()
    {
        this.viewModel.AfterContentInit();
    }

    ngAfterContentChecked()
    {
        this.viewModel.AfterContentChecked();
    }

    ngOnDestroy()
    {
        this.viewModel.OnDestroy();
    }

    //--------------------------------------------------------------------------------------------
    // PUBLIC METHODS
    //--------------------------------------------------------------------------------------------
    void()
    {
    }
}
