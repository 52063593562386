declare let require: any;

import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs';

declare let beTracker: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  //--------------------------------------------------------------------------------------------
  // CONSTRUCTOR
  //--------------------------------------------------------------------------------------------
  constructor(
    @Inject(TranslateService) private _translateService: TranslateService,

    private _router: Router,
    private _titleService: Title
  ) {
    this._translateService.addLangs(['en-US', 'es-MX']);
    this._translateService.setDefaultLang('en-US');

    const localLang: string = localStorage.getItem('NG_TRANSLATE_LANG_KEY');
    const browserLang: string = this._translateService.getBrowserLang();
    let lang = localLang
      ? localLang
      : browserLang.match(/en\-US|es\-MX/)
      ? browserLang
      : this._translateService.getDefaultLang();

    this._translateService.use(lang);
  }

  // --------------------------------------------------------------------------------------------
  // PROPERTIES
  // --------------------------------------------------------------------------------------------
  a: number = 0;
  title = 'ZUNI';

  //--------------------------------------------------------------------------------------------
  // COMMAND HANDLERS
  //--------------------------------------------------------------------------------------------

  // --------------------------------------------------------------------------------------------
  // INTERFACE
  // --------------------------------------------------------------------------------------------
  ngOnInit() {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this._router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          if (this.a === 0) {
            title =
              this._translateService.currentLang === 'es-MX'
                ? 'Marketing sensorial'
                : 'Sensory marketing';
            this.a++;
          }

          this._titleService.setTitle(
            `ZUNI | ${this._translateService.instant(title)}`
          );
        }
      });

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loadMetricoolScript();
      }
    });
  }

  loadMetricoolScript() {
    const scriptId = 'metricool-script';

    if (document.getElementById(scriptId)) {
      return;
    }

    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://tracker.metricool.com/resources/be.js';
    script.id = scriptId;

    script.onload = function () {
      beTracker.t({ hash: 'b018fcde5e73007ad001f0617644f401' });
    };

    head.appendChild(script);
  }
}
