import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ContactAdviserButtonComponent } from './contact-adviser-button/contact-adviser-button.component';
import { AudioPlayerLDDComponent } from './audio-player-ldd/audio-player-ldd.component';
import { AudioPlayerCommercialComponent } from './audio-player-commercial/audio-player-commercial.component';
import { SongContainerComponent } from './song-container/song-container.component';

@NgModule(
    {
        imports:
            [
                CommonModule,
                TranslateModule
            ],
        declarations:
            [
                ContactAdviserButtonComponent,
                AudioPlayerLDDComponent,
                AudioPlayerCommercialComponent,
                SongContainerComponent
            ],
        exports:
            [
                ContactAdviserButtonComponent,
                AudioPlayerLDDComponent,
                AudioPlayerCommercialComponent,
                SongContainerComponent
            ]
    }
)
export class ControlsModule
{
}
