import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class AppState
{
    // public notifyExitModal: EventEmitter<void> = new EventEmitter<void>();
    // public notifySignOut: EventEmitter<void> = new EventEmitter<void>();

    // public pendingUploadItemsCount: number = 0;
}
