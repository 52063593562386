import { Injectable } from '@angular/core';
import { ServiceBase } from '../../common/service-base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { UserModel } from '../../users/user.model';
import { UserSignInArgs } from './session.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService extends ServiceBase {

  //--------------------------------------------------------------------------------------------
  // CONSTRUCTOR
  //--------------------------------------------------------------------------------------------
  constructor(protected http: HttpClient)
  {
    super(http);
  }

  //--------------------------------------------------------------------------------------------
  // PUBLIC METHODS
  //--------------------------------------------------------------------------------------------
  public SignIn(userSignInArgs: UserSignInArgs): Promise<UserModel>
  {
    return this.Post(BASE_URL + SIGN_IN_URL, { Email: userSignInArgs.Email, Password: userSignInArgs.Password });
  }

  public TestJWT(): Promise<any>
  {
    return this.Get(BASE_URL + TEST_JWT_URL);
  }
}

//--------------------------------------------------------------------------------------------
// URLS 
//--------------------------------------------------------------------------------------------
const BASE_URL: string = environment.url_base; // ! Change to... Production URL
const SIGN_IN_URL: string = "security/auth/sign-in";
const TEST_JWT_URL: string = "security/auth/test-jwt";